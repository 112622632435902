<template>
  <div>
        <v-toolbar
          elevation="1"
        >
          <v-icon style="margin-right: 10px;">mdi-ticket</v-icon>
          <v-toolbar-title>
            {{ $t('booking.activities.title', locale) }}
          </v-toolbar-title>
          <v-spacer />
            <search :onSearch="handleSearch" />
        </v-toolbar>
    <draggable
      v-model="items"
      :draggable="'.itemDraggable'"
      style="width:100%;"
      v-bind="dragOptions"
      @start="handleSortStart"
      @end="handleSortItems(items)"
    >
    <div style="cursor: pointer;" 
      v-for="item in items" 
      :key="item.ID + item.Name" 
      v-bind:class="'itemDraggable'"
    >
        <v-toolbar elevation="1" style="cursor: default;">
          <!--v-icon small style="margin-right: 10px;">mdi-cursor-move</v-icon-->
          <v-toolbar-title class="title" 
            style="cursor: pointer;"
          >
            <a 
              color="primary"
              @click.stop.prevent="onEdit(item.ID)"
            >
              {{ getTranslation(item.Name, locale) }}
            </a>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            :color="item.Hide ? 'red' : 'green'"
            @click.stop.prevent="handleHide(item.ID, item.Hide)"
          >
            <v-icon small>{{ item.Hide ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
          </v-btn>
          <v-btn
            icon
            @click.stop.prevent="onEdit(item.ID)"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </v-toolbar>
      </div>
    </draggable>
    <pagination 
      :count="count"
      :startIndex="startIndex"
      :itemsPerPage="itemsPerPage"
      :locale="locale"
      :onGetData="handleGetData"
      :onChangeItemsPerPage="handleChangeItemsPerPage"
    />
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import utils from '@/services/utils'
import api from '@/services/api'
import Search from '@/components/Search'
import Pagination from './Pagination'
export default {
  name: 'ActivityList',
  components: {
    draggable,
    Search,
    Pagination,
  },
  props: {
    onEdit: {
      type: Function,
      required: true,
    },
    onHide: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
    updateData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    items: [],
    count: 0,
    startIndex: 0,
    itemsPerPage: 5,
    search: '',
  }),
  computed: {
    workspaceID () {
      return utils.getWorkspace('booking')
    },
    dragOptionsDisabled() {
      return {
        animation: 200,
        group: "description",
        disabled: true,
        ghostClass: "ghost"
      }
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
  },
  watch: {
    updateData (v) {
      if (v)
        if (v.action === 'add') {
          // if action is add go to last page
          const newPage = this.getLastPage(this.itemsPerPage, v.data.count)
          this.handleGetData(newPage)
        } else if (v.action === 'delete') {
          this.handleGetData(this.startIndex, true)
        } else {
          this.handleGetData(this.startIndex)
        }
    },
  },
  mounted () {
    this.handleGetData (0)
  },
  methods: {
    getLastPage (itemsPerPage, count) {
      const aux = count / itemsPerPage
      const p = parseInt(aux, 10)
      return (aux % 1 > 0 ? p : p-1) * itemsPerPage
    },
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleGetData (startIndex, forceSort = false) {
      this.startIndex = startIndex
      api.getAll ('booking', `v1/private/workspaces/${this.workspaceID}/activities`, this.startIndex, this.itemsPerPage, this.search ? this.search : '')
        .then(response => {
          this.count = response.count
          this.items = response.data
          if (forceSort) this.handleSortItems()
          this.loading = false
        })
    },
    handleHide (v, hide) {
      api.put ('booking', `v1/private/activities/${v}/hide`, { hide: hide ? false : true })
        .then(response => {
          console.log(response)
          this.handleGetData()
        })
    },
    handleSortStart () {

    },
    handleSortItems () {
      const body = []
      let iniORD = this.startIndex
      for (const item of this.items) {
        body.push({
          id: item.ID,
          ord: iniORD,
        })
        iniORD++
      }
      api.put('booking', 'v1/private/activities/sort', body)
    },
    handleSearch (v) {
      this.search = v
      this.handleGetData(0)
    },
    handleChangeItemsPerPage(v) {
      this.itemsPerPage = v
      this.handleGetData(0)
    },
  },
}
</script>
<style scoped>
.itemDraggable .title {
}
</style>

